<template>
  <div class="list" v-if="companyInfo">
    <div class="item">
      <div class="icon">
        <van-icon name="wap-home-o" color="#fff"/>
      </div>
      <div class="tit">公司名称:</div>
      <div class="txt">{{companyInfo.name}}</div>
    </div>
    <div class="item">
      <div class="icon">
        <van-icon name="location-o" color="#fff"/>
      </div>
      <div class="tit">公司地址:</div>
      <div class="txt">{{companyInfo.address}}</div>
    </div>
    <div class="item">
      <div class="icon">
        <van-icon name="phone-circle-o" color="#fff"/>
      </div>
      <div class="tit">联系电话:</div>
      <div class="txt"><a class="tel" :href="'tel:'+companyInfo.mobile">{{companyInfo.mobile}}</a></div>
    </div>
    <div class="item">
      <div class="icon">
        <van-icon name="underway-o" color="#fff"/>
      </div>
      <div class="tit">工作时间:</div>
      <div class="txt">{{companyInfo.workday}}</div>
    </div>
    <div class="addr-item">
      <div class="copy-addr" @click="copyAddr(companyInfo.name,companyInfo.mobile,companyInfo.address)">一键复制收件地址</div>
    </div>
  </div>
</template>

<script>
  import { copyFn } from 'utils/util'
  export default {
    props: {
      companyInfo: {
        type: Object,
        default(){
          return null
        }
      }
    },
    methods:{
      copyAddr(name,mobile,address){
        let addrStr =
`联系人：${name}
联系电话：${mobile}
收件地址：${address}`
        copyFn('收货地址',addrStr)
      }
    }
  }
</script>

<style lang="less" scoped>

  .list {
    padding: 0 0.3rem;
    margin-bottom: 0.3rem;
    background-color: #fff;

    .item {
      display: flex;
      padding: 0.3rem 0;
      font-size: 0.3rem;
      line-height: 0.5rem;
      border-bottom: 1px solid #b5b5b5;

      &:last-child {
        border-bottom: 0;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 0.44rem;
        height: 0.44rem;
        border-radius: 50%;
        background-color: #ff5100;
        text-align: center;
        margin-right: 0.15rem;
      }

      .tit {
        flex: 0 0 5.5em;
      }

      .txt {
        flex: 1;
        .tel{
          display: block;
          width: 100%;
          height: 100%;
          color: #000;
        }
      }
    }
    .addr-item{
      padding: 0.5rem 0.3rem;
      text-align: center;
      .copy-addr{
        display: inline-block;
        height: 0.8rem;
        line-height: 0.8rem;
        padding: 0 0.5rem;
        font-size: 0.28rem;
        background-color: #ff5100;
        border-radius: 0.4rem;
        color: #fff;
      }
    }
  }
</style>
