<template>
  <div class="page-container">
    <div class="header">
      <img class="logo" src="~assets/images/profile/contact-us.png" alt=""/>
    </div>
    <company-list :companyInfo="companyInfo"></company-list>
    <main-tab-bar/>
  </div>
</template>

<script>
  import { getContactInfo } from 'api/api'
  import MainTabBar from 'components/content/mainTabbar/MainTabBar'
  import CompanyList from './childComps/CompanyList'

  export default {
    name: 'profile',
    data () {
      return {
        companyInfo: null
      }
    },
    components: {
      MainTabBar,
      CompanyList
    },
    methods: {
      getContactInfo () {
        getContactInfo().then(res => {
          this.companyInfo = res
        })
      }
    },
    created () {
      this.getContactInfo()
    }
  }
</script>

<style lang="less" scoped>
  .page-container {
    padding-bottom: 49px;
  }

  .header {
    height: 2.2rem;
    margin-bottom: 0.2rem;

    .logo {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
</style>
