import app from '../main'

// 对Date的扩展，将 Date 转化为指定格式的String
const formatDate = function (fmt, date) {
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(),        // 年
    'm+': (date.getMonth() + 1).toString(),     // 月
    'd+': date.getDate().toString(),            // 日
    'H+': date.getHours().toString(),           // 时
    'M+': date.getMinutes().toString(),         // 分
    'S+': date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    }
  }
  return fmt
}

const copyFn = function (info, data) {
  app.$copyText(data).then(function (e) {
    app.$toast.success(info +'\n复制成功')
  }, function (e) {
    app.$toast.fail('复制失败\n请联系客服')
  })

}

export {
  copyFn,
  formatDate
}

